body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: 'Open Sans';
  -moz-osx-font-smoothing: 'Open Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
