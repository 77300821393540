.badge
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
    font-size: 75%;
    font-weight: 500;
    background-color: #d8d6d6;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    width: 8rem;
    height: 6rem;
    border-radius: 0.5rem !important; 
}

.badge.active
{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
}


.badge-icon
{
    margin-bottom: 0.25rem;
    color: #adadad;
    width: 100%;
    height: 100%;
}

.badge-name
{
    font-size: 1rem;
    color: #949494;
    width: 100%;
    height: 100%;
}


.active .green
{
    color: #609350 !important;
}

.active .red
{
    color: #D2042D !important;
}

.active .yellow
{
    color: #FFAA33 !important;
}

.badge-card
{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    background-color: #d8d6d6;
    border-radius: 0.25rem;
    /* margin: 0.5rem; */
    width: 100%;
    transition: all 0.2s ease-in-out;
}

.badge-card-body
{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    padding: 0.5rem;
    width: 100%;
}

.icon-size
{
    font-size: 2rem;
}