.shadow {
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
    box-shadow: 0px 0px 31px 0px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    /* background-color: black; */
    /* opacity: 0.5; */
    color: white;
    /* width: 400px; */
    /* height: 400px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
}

.box{
    width: 100% !important;
    padding: 0.5rem 0 0.5rem 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--backgrud-light-blue-color);
    /* border-image: linear-gradient(to right, var(--backgrud-light-blue-color), var(--backgrud-light-blue-color)) 1; */
    border-radius: 3rem;
}

.counter-icon {
    display: block;
    font-size: 60px;
    /* margin: 7px 0; */
    height: 100px;
  }

.btn-purpole{
    color: white !important;
    background-color: var(--backgrud-purpole-color) !important;
    border-color: var(--backgrud-purpole-color) !important;
}

.link-purpole, a.link-purpole:hover, .link-purpole:focus{
    color: var(--text-purpole-color) !important;

}