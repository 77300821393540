.Image-size
{
    width: 100%;
    height: 100%;
}
.txt-white
{
    color: white;
}
.blue{
    color: #2C68EF;
  }

  .bg-white{
    background-color:  #D0C8BB;
}

.fs-7
{
    font-size: 0.8rem;
}
.corsor
{
    cursor: pointer !important;
}
label{
    cursor: pointer !important;
}

p.title
{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    padding: 0.4rem 0;
}