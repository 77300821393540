:root {
  --white-color: #fff;
  --backgrud-blue-color:  #66b2c0;
  --backgrud-purpole-color: #8a53aa;
  --backgrud-light-blue-color: #b9d4db;
  --text-blue-color: #66b2c0;
  --text-purpole-color: #8b53aa;
  --text-black-color: #666666;
}


.text-blue{
  color: var(--text-blue-color);
}

.text-white{
  color: var(--white-color);
}
.text-purpole{
  color: var(--text-purpole-color);
}

.text-black{
  color: var(--text-black-color);
}

.bg-purpole{
  background: var(--text-purpole-color) !important;
}
.App {
  text-align: start;
}

.App-logo {
  height: 3.2rem;
  width: auto;
  pointer-events: none;
   position: relative;
   top: 0;
   bottom: 0;
   margin-bottom: 1rem;
}



.App-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  font-size: calc(10px + 2vmin);
  color: rgb(7, 7, 7);
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}

.App-link {
  color: #61dafb;
}

.Form-image
{
  background-color: #007bff;
  color: #011525;
}



.txt
{
  align-items: center;
  text-align: center;
}

.txt>.fs-4
{
   font-weight: 300;
}

.btn-purpole{
  color: white !important;
  background-color: var(--backgrud-purpole-color) !important;
  border-color: var(--backgrud-purpole-color) !important;
}



.spinner-container
{
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(215, 215, 215, 0.8);
  backdrop-filter: blur(3px);
  z-index: 2;
}
.loading-spinner {
  width: 10vw;
  height: 10vw;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #4257f5; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media only screen and (max-device-width: 800px) {

  .loading-spinner
  {
    width: 15vw;
    height: 15vw;
  }
  .App-logo
  {
    height: 4.5rem;
   width: auto;

  }
  .fs-3
  {
    font-size: calc(1.2rem + .6vw)!important;
  }
  .fs-5
  {
    font-size: 1.2rem!important;
  }

}