.App-logo {
    height: 5rem;
    width: auto;
    pointer-events: none;
     position: relative;
     top: 0;
     bottom: 0;
     margin-bottom: 1rem;
  }

  .image-connert
  {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }