.result_badge {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.result_badge:hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4); 
    transform: scale(1.05);
    border-radius: 3%;
}

.result_badge:hover .result_badge__title h3 {
    color: #000;
}

.result_badge img {
    width: 10rem;
    height: 10rem;
    /* border-radius: 50%; */
    object-fit: cover;
}

.result_badge__title h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(109, 109, 109);
    margin-top: 15px;
    text-align: center;
    transition: all 0.2s ease-in-out;

}


.result_txt
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    text-align: center;
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.result_txt h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(109, 109, 109);
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.result_txt p {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 15px;
    text-align: justify;
    color: rgb(161, 161, 161);
    transition: all 0.2s ease-in-out;
}

.result_txt_title
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.result_txt_title h3, h3.Ai_Results_title{
    font-size: 1.5rem;
    font-weight: 500;
    color: rgb(109, 109, 109);
    padding: 0 0.3rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

h3.red_txt
{
    color: rgb(220, 2, 2);
}

h3.green_txt
{
    color: rgb(2, 220, 2);
}

h3.yellow_txt
{
    color: rgb(220, 220, 2);
}

.result_txt img {
    width: 6rem;
    height: 6rem;
    /* border-radius: 50%; */
    object-fit: cover;
}

.Ai_Results,.Prediction_Results,.Ml_result
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
    /* margin-top: 15px; */
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.Orignal_img{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}


.Prediction_img
{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    text-align: center;
    margin-top: 15px;
    /* border-radius: 0.5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.Ai_Results_box
{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: rgb(209, 215, 222);
    margin-top: 15px;
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}

.Ai_Results_box_item 
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
}

.Ai_Results_box_item  h3 
{
    font-size: 1rem;
    font-weight: 500;
    color: rgb(69, 69, 69);
    padding: 0 1.5vw;
    text-align: center;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
}



.Orignal_img img,.Prediction_img img
{
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
    object-fit: cover;
}

.Orignal_img h3,.Prediction_img h3
{
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(109, 109, 109);
    padding: 0 0.3rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.Ml_result button
{
    font-size: large;
    font-weight: 500;
    padding: 0.5rem 1rem;
    
    /* box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2); */
    transition: all 0.2s ease-in-out;
}


.image
{
  height: 85%;
  width: 100%;
}

.txt-black
{
    color: rgb(109, 109, 109);
}