

.doctorlist{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d4d0d0;
    background: #ffffff;
    flex-direction: column;
    padding-right: 2rem !important;
    /* width:50%; */
    border-radius: 30px;
   
}

.doctor{
    display: flex;
    /* justify-content: space-around; */
    align-items: center; 
    flex-wrap: wrap;
    border-bottom: 1px solid #d4d0d0;
}

.doctor-li{
    list-style: none;
    border-bottom: 1px solid #d4d0d0;
}

.details{
    display: flex;
    flex-direction: column;
    line-height: 1rem;
    text-align: center;
    
}


@media only screen and (max-device-width: 800px) {
    .channel{
        margin-bottom: 1rem;
    }

    .details{
        margin-bottom: 1rem;
        flex-wrap: wrap;
    }

    .doctorlist{
        justify-content: center;
        align-items: center;
    }

    .icon{
        margin-bottom: 1rem;
    }
}
